var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search" },
    [
      _c(
        "Card",
        { staticStyle: { "min-height": "600px" } },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "edit-head" }, [
              _c(
                "a",
                { staticClass: "back-title", on: { click: _vm.close } },
                [
                  _c("Icon", { attrs: { type: "ios-arrow-back" } }),
                  _vm._v("返回 "),
                ],
                1
              ),
              _c("div", { staticClass: "head-name" }, [
                _vm._v(_vm._s(_vm.processName) + " 流程配置"),
              ]),
              _c("span"),
              _c(
                "a",
                { staticClass: "window-close", on: { click: _vm.close } },
                [
                  _c("Icon", {
                    staticClass: "ivu-icon-ios-close",
                    attrs: { type: "ios-close", size: "31" },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                margin: "10px 0 30px 0",
              },
            },
            [
              _c(
                "Steps",
                {
                  staticStyle: { width: "400px" },
                  attrs: { current: _vm.current },
                },
                [
                  _c("Step", { attrs: { title: "基本配置" } }),
                  _c("Step", { attrs: { title: "节点审批人配置" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.current == 0,
                  expression: "current==0",
                },
              ],
              attrs: { type: "flex", justify: "center" },
            },
            [
              _c(
                "Col",
                { attrs: { xs: 24, md: 24, lg: 16, xl: 9 } },
                [
                  _c(
                    "Form",
                    {
                      ref: "basicForm",
                      attrs: {
                        model: _vm.basicForm,
                        "label-position": "left",
                        "label-width": 110,
                        rules: _vm.formValidate,
                      },
                    },
                    [
                      _c(
                        "FormItem",
                        { attrs: { label: "关联表单路由", prop: "routeName" } },
                        [
                          _c(
                            "Select",
                            {
                              attrs: {
                                placeholder: "请选择关联业务表单前端路由名",
                                clearable: "",
                              },
                              on: { "on-change": _vm.handelSubmitEdit },
                              model: {
                                value: _vm.basicForm.routeName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.basicForm, "routeName", $$v)
                                },
                                expression: "basicForm.routeName",
                              },
                            },
                            _vm._l(_vm.dictForm, function (item, i) {
                              return _c(
                                "Option",
                                {
                                  key: i,
                                  attrs: {
                                    value: item.value,
                                    label: item.title,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-right": "10px" } },
                                    [_vm._v(_vm._s(item.title))]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#ccc" } },
                                    [_vm._v(_vm._s(item.value))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        {
                          attrs: { label: "关联业务表", prop: "businessTable" },
                        },
                        [
                          _c(
                            "Select",
                            {
                              attrs: {
                                placeholder: "请选择关联数据库业务表",
                                clearable: "",
                              },
                              on: { "on-change": _vm.handelSubmitEdit },
                              model: {
                                value: _vm.basicForm.businessTable,
                                callback: function ($$v) {
                                  _vm.$set(_vm.basicForm, "businessTable", $$v)
                                },
                                expression: "basicForm.businessTable",
                              },
                            },
                            _vm._l(_vm.dictTable, function (item, i) {
                              return _c(
                                "Option",
                                {
                                  key: i,
                                  attrs: {
                                    value: item.value,
                                    label: item.title,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-right": "10px" } },
                                    [_vm._v(_vm._s(item.title))]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#ccc" } },
                                    [_vm._v(_vm._s(item.value))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c("FormItem", { attrs: { label: "流程分类" } }, [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c("Input", {
                              staticStyle: { "margin-right": "10px" },
                              attrs: { readonly: "", clearable: "" },
                              on: { "on-clear": _vm.clearSelectCat },
                              model: {
                                value: _vm.categoryTitle,
                                callback: function ($$v) {
                                  _vm.categoryTitle = $$v
                                },
                                expression: "categoryTitle",
                              },
                            }),
                            _c(
                              "Poptip",
                              {
                                attrs: {
                                  trigger: "click",
                                  placement: "right",
                                  title: "选择类别",
                                  width: "250",
                                },
                              },
                              [
                                _c("Button", { attrs: { icon: "md-list" } }, [
                                  _vm._v("选择分类"),
                                ]),
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _c("Input", {
                                      attrs: {
                                        suffix: "ios-search",
                                        placeholder: "输入分类名搜索",
                                        clearable: "",
                                      },
                                      on: { "on-change": _vm.searchCat },
                                      model: {
                                        value: _vm.searchKey,
                                        callback: function ($$v) {
                                          _vm.searchKey = $$v
                                        },
                                        expression: "searchKey",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "tree-list" },
                                      [
                                        _c("Tree", {
                                          attrs: {
                                            data: _vm.dataCat,
                                            "load-data": _vm.loadDataTree,
                                            render: _vm.renderCatContent,
                                          },
                                        }),
                                        _vm.catLoading
                                          ? _c("Spin", {
                                              attrs: { size: "large", fix: "" },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "FormItem",
                        { attrs: { label: "备注描述", prop: "description" } },
                        [
                          _c("Input", {
                            attrs: { type: "textarea", rows: 4 },
                            on: { "on-blur": _vm.handelSubmitEdit },
                            model: {
                              value: _vm.basicForm.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.basicForm, "description", $$v)
                              },
                              expression: "basicForm.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        { staticClass: "br" },
                        [
                          _c(
                            "Button",
                            {
                              attrs: { type: "warning" },
                              on: {
                                click: function ($event) {
                                  _vm.current = 1
                                },
                              },
                            },
                            [
                              _vm._v(" 下一步 "),
                              _c("Icon", {
                                attrs: { type: "ios-arrow-forward" },
                              }),
                            ],
                            1
                          ),
                          _c("Button", { on: { click: _vm.close } }, [
                            _vm._v("关闭"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.current == 1
            ? _c(
                "Row",
                { attrs: { type: "flex", justify: "start" } },
                [
                  _c(
                    "Col",
                    { attrs: { md: 8, lg: 8, xl: 6 } },
                    [
                      _c("Alert", { attrs: { "show-icon": "" } }, [
                        _vm._v(" 当前选择编辑： "),
                        _c("span", { staticClass: "select-title" }, [
                          _vm._v(_vm._s(_vm.editTitle)),
                        ]),
                        _vm.form.id
                          ? _c(
                              "a",
                              {
                                staticClass: "select-clear",
                                on: { click: _vm.cancelEdit },
                              },
                              [_vm._v("取消选择")]
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "tree-bar" },
                        [
                          _c("Tree", {
                            ref: "tree",
                            attrs: {
                              data: _vm.data,
                              render: _vm.renderContent,
                            },
                            on: { "on-select-change": _vm.selectTree },
                          }),
                          _vm.loading
                            ? _c("Spin", { attrs: { size: "large", fix: "" } })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "Col",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { md: 15, lg: 13, xl: 10 },
                    },
                    [
                      _c(
                        "Alert",
                        {
                          staticStyle: { "margin-left": "1vw" },
                          attrs: { type: "warning", "show-icon": "" },
                        },
                        [
                          _vm._v(
                            "温馨提示：若流程运行至未分配审批人员的审批节点时，流程将自动中断取消"
                          ),
                        ]
                      ),
                      _c(
                        "Form",
                        {
                          ref: "form",
                          staticStyle: { position: "relative" },
                          attrs: { model: _vm.form, "label-width": 90 },
                        },
                        [
                          _c(
                            "FormItem",
                            { attrs: { label: "节点名称", prop: "title" } },
                            [
                              _vm._v(" " + _vm._s(_vm.form.title) + " "),
                              _c(
                                "Tooltip",
                                {
                                  staticStyle: {
                                    display: "inline-block !important",
                                  },
                                  attrs: {
                                    content:
                                      "配置可看到该流程的所属用户，默认所有人可发起",
                                    placement: "right",
                                    transfer: "",
                                    "max-width": "280",
                                  },
                                },
                                [
                                  _c("Icon", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.form.type == 0,
                                        expression: "form.type==0",
                                      },
                                    ],
                                    staticStyle: {
                                      "margin-left": "5px",
                                      cursor: "pointer",
                                    },
                                    attrs: {
                                      type: "md-help-circle",
                                      size: "20",
                                      color: "#c5c5c5",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "FormItem",
                            { attrs: { label: "节点类型", prop: "type" } },
                            [
                              _c(
                                "Select",
                                {
                                  staticStyle: { width: "300px" },
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.form.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "type", $$v)
                                    },
                                    expression: "form.type",
                                  },
                                },
                                _vm._l(_vm.dictNodeType, function (item, i) {
                                  return _c(
                                    "Option",
                                    { key: i, attrs: { value: item.value } },
                                    [_vm._v(_vm._s(item.title))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.form.type == 0,
                                  expression: "form.type==0",
                                },
                              ],
                            },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "可发起人员" } },
                                [
                                  _c(
                                    "Checkbox",
                                    {
                                      model: {
                                        value: _vm.allUser,
                                        callback: function ($$v) {
                                          _vm.allUser = $$v
                                        },
                                        expression: "allUser",
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        staticStyle: { margin: "0 2px 0 0" },
                                        attrs: { type: "md-contacts" },
                                      }),
                                      _c("span", [_vm._v("所有人")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Checkbox",
                                    {
                                      attrs: { disabled: _vm.allUser },
                                      on: { "on-change": _vm.clickRole },
                                      model: {
                                        value: _vm.chooseRole,
                                        callback: function ($$v) {
                                          _vm.chooseRole = $$v
                                        },
                                        expression: "chooseRole",
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        staticStyle: { margin: "0 2px 0 0" },
                                        attrs: {
                                          type: "md-people",
                                          size: "14",
                                        },
                                      }),
                                      _c("span", [_vm._v("指定角色")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Checkbox",
                                    {
                                      attrs: { disabled: _vm.allUser },
                                      on: { "on-change": _vm.clickDepartment },
                                      model: {
                                        value: _vm.chooseDepartment,
                                        callback: function ($$v) {
                                          _vm.chooseDepartment = $$v
                                        },
                                        expression: "chooseDepartment",
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        staticStyle: { margin: "0 2px 0 0" },
                                        attrs: { type: "ios-people" },
                                      }),
                                      _c("span", [_vm._v("指定部门")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Checkbox",
                                    {
                                      attrs: { disabled: _vm.allUser },
                                      on: { "on-change": _vm.clickUser },
                                      model: {
                                        value: _vm.chooseUser,
                                        callback: function ($$v) {
                                          _vm.chooseUser = $$v
                                        },
                                        expression: "chooseUser",
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        staticStyle: { margin: "0 2px 0 0" },
                                        attrs: { type: "md-person" },
                                      }),
                                      _c("span", [_vm._v("指定人员")]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.form.type == 1,
                                  expression: "form.type==1",
                                },
                              ],
                            },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "可审批人员" } },
                                [
                                  _c(
                                    "Checkbox",
                                    {
                                      attrs: { disabled: _vm.form.customUser },
                                      on: { "on-change": _vm.clickRole },
                                      model: {
                                        value: _vm.chooseRole,
                                        callback: function ($$v) {
                                          _vm.chooseRole = $$v
                                        },
                                        expression: "chooseRole",
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        staticStyle: { margin: "0 2px 0 0" },
                                        attrs: {
                                          type: "md-people",
                                          size: "14",
                                        },
                                      }),
                                      _c("span", [_vm._v("指定角色")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Checkbox",
                                    {
                                      attrs: { disabled: _vm.form.customUser },
                                      on: { "on-change": _vm.clickDepartment },
                                      model: {
                                        value: _vm.chooseDepartment,
                                        callback: function ($$v) {
                                          _vm.chooseDepartment = $$v
                                        },
                                        expression: "chooseDepartment",
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        staticStyle: { margin: "0 2px 0 0" },
                                        attrs: { type: "ios-people" },
                                      }),
                                      _c("span", [_vm._v("指定部门负责人")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Checkbox",
                                    {
                                      attrs: { disabled: _vm.form.customUser },
                                      on: { "on-change": _vm.clickUser },
                                      model: {
                                        value: _vm.chooseUser,
                                        callback: function ($$v) {
                                          _vm.chooseUser = $$v
                                        },
                                        expression: "chooseUser",
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        staticStyle: { margin: "0 2px 0 0" },
                                        attrs: { type: "md-person" },
                                      }),
                                      _c("span", [_vm._v("指定人员")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Checkbox",
                                    {
                                      model: {
                                        value: _vm.form.customUser,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "customUser", $$v)
                                        },
                                        expression: "form.customUser",
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        staticStyle: { margin: "0 2px 0 0" },
                                        attrs: { type: "md-contacts" },
                                      }),
                                      _c("span", [_vm._v("审批人自选")]),
                                      _c(
                                        "Tooltip",
                                        {
                                          staticStyle: {
                                            display: "inline-block !important",
                                          },
                                          attrs: {
                                            content:
                                              "审批人任意自选用户，请勿在网关后设置",
                                            placement: "right",
                                            transfer: "",
                                            "max-width": "280",
                                          },
                                        },
                                        [
                                          _c("Icon", {
                                            staticStyle: {
                                              "margin-left": "10px",
                                              cursor: "pointer",
                                            },
                                            attrs: {
                                              type: "md-help-circle",
                                              size: "20",
                                              color: "#c5c5c5",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Checkbox",
                                    {
                                      attrs: { disabled: _vm.form.customUser },
                                      model: {
                                        value: _vm.form.chooseDepHeader,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "chooseDepHeader",
                                            $$v
                                          )
                                        },
                                        expression: "form.chooseDepHeader",
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        staticStyle: { margin: "0 2px 0 0" },
                                        attrs: { type: "md-person" },
                                      }),
                                      _c("span", [
                                        _vm._v("连续多级部门负责人"),
                                      ]),
                                      _c(
                                        "Tooltip",
                                        {
                                          staticStyle: {
                                            display: "inline-block !important",
                                          },
                                          attrs: {
                                            content:
                                              "自动获取发起人或上一审批人的部门负责人",
                                            placement: "right",
                                            transfer: "",
                                            "max-width": "280",
                                          },
                                        },
                                        [
                                          _c("Icon", {
                                            staticStyle: {
                                              "margin-left": "10px",
                                              cursor: "pointer",
                                            },
                                            attrs: {
                                              type: "md-help-circle",
                                              size: "20",
                                              color: "#c5c5c5",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "FormItem",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.chooseRole &&
                                    ((_vm.form.type == 0 && !_vm.allUser) ||
                                      (_vm.form.type == 1 &&
                                        !_vm.form.customUser)),
                                  expression:
                                    "chooseRole&&((form.type==0&&!allUser)||(form.type==1&&!form.customUser))",
                                },
                              ],
                              attrs: { label: "选择角色" },
                            },
                            [
                              _c(
                                "Select",
                                {
                                  attrs: { multiple: "" },
                                  model: {
                                    value: _vm.form.roles,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "roles", $$v)
                                    },
                                    expression: "form.roles",
                                  },
                                },
                                _vm._l(_vm.roleList, function (item) {
                                  return _c(
                                    "Option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        value: item.id,
                                        label: item.name,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#ccc" } },
                                        [_vm._v(_vm._s(item.description))]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.chooseDepartment &&
                                    ((_vm.form.type == 0 && !_vm.allUser) ||
                                      (_vm.form.type == 1 &&
                                        !_vm.form.customUser)),
                                  expression:
                                    "chooseDepartment&&((form.type==0&&!allUser)||(form.type==1&&!form.customUser))",
                                },
                              ],
                            },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "选择部门" } },
                                [
                                  _c("department-tree-choose", {
                                    ref: "depTree",
                                    attrs: { multiple: "", width: "250px" },
                                    on: {
                                      "on-change": _vm.handleSelectDepTree,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.chooseUser &&
                                    ((_vm.form.type == 0 && !_vm.allUser) ||
                                      (_vm.form.type == 1 &&
                                        !_vm.form.customUser)),
                                  expression:
                                    "chooseUser&&((form.type==0&&!allUser)||(form.type==1&&!form.customUser))",
                                },
                              ],
                            },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "选择用户" } },
                                [
                                  _c("user-choose", {
                                    ref: "user",
                                    on: { "on-change": _vm.handleSelectUser },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "FormItem",
                            { staticClass: "br" },
                            [
                              _c(
                                "Button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.submitLoading,
                                    icon: "ios-create-outline",
                                    disabled:
                                      _vm.form.type != 0 && _vm.form.type != 1,
                                  },
                                  on: { click: _vm.handelSubmit },
                                },
                                [_vm._v("保存并提交")]
                              ),
                              _c(
                                "Button",
                                {
                                  attrs: {
                                    type: "warning",
                                    icon: "ios-arrow-back",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.current = 0
                                    },
                                  },
                                },
                                [_vm._v("上一步")]
                              ),
                              _c("Button", { on: { click: _vm.close } }, [
                                _vm._v("关闭"),
                              ]),
                            ],
                            1
                          ),
                          _vm.nodeLoading
                            ? _c("Spin", { attrs: { size: "large", fix: "" } })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }